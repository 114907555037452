import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const OneScreensWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
`

const getData = graphql`
  {
    oneScreens: file(relativePath: { eq: "singleScreen.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const OneScreens = () => {
  const data = useStaticQuery(getData)
  const imageData = data.oneScreens.childImageSharp.fluid
  return (
    <OneScreensWrap>
      <Img fluid={imageData} alt="Kona" />
    </OneScreensWrap>
  )
}

export default OneScreens
