import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const ThreeScreensWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
`

const getData = graphql`
  {
    threeScreens: file(relativePath: { eq: "bi-three-screens-rev.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ThreeScreens = () => {
  const data = useStaticQuery(getData)
  const imageData = data.threeScreens.childImageSharp.fluid
  return (
    <ThreeScreensWrap>
      <Img fluid={imageData} alt="Kona" />
    </ThreeScreensWrap>
  )
}

export default ThreeScreens
