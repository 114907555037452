import React, { useContext } from "react"
import styled from "styled-components"

import { GlobalDispatchContext } from "../../../context/GlobalContextProvider"

import { H2DarkGrey, B1DarkGrey, colors } from "../../../styles/helpers"

const InformationItem = styled.div`
  width: calc(100%);
  text-align: center;

  @media (min-width: 768px) {
    width: calc(33.33% - 6rem);
    margin: 1rem 3rem;
  }

  .itemTitle {
    h2 {
      ${H2DarkGrey};
    }
  }

  .itemContent {
    p {
      ${B1DarkGrey};
    }
  }

  button {
    width: 3rem;
    height: 3rem;
    transition: all 0.3s ease;
    background-color: ${colors.colorPrimary};
    font-size: 2.5rem;
    cursor: pointer;
    border: none;
    border-radius: 50%;

    &:hover {
      background-color: ${colors.colorSecondary};
      color: ${colors.white};
    }
  }
`

const ThreeColumnsSection = ({ setWhichActive, data }) => {
  const dispatch = useContext(GlobalDispatchContext)
  return (
    <InformationItem>
      <div className="itemTitle">
        <h2>{data.title}</h2>
      </div>
      <div
        className="itemContent"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
      <button
        onClick={() => {
          dispatch({ type: "TOGGLE_ACTIVE", payload: data })
          setWhichActive(data.index)
        }}
      >
        &#43;
      </button>
    </InformationItem>
  )
}

export default ThreeColumnsSection
