import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  H1DarkGrey,
  B1DarkGrey,
  standardWrapper,
} from "../../../styles/helpers"

const LogoContentSection = styled.section`
  padding-top: 5rem;

  .wrapper {
    ${standardWrapper};
  }

  .secLogo {
    width: 100%;

    &__wrapper {
      max-width: 20rem;
      margin: auto;
    }
  }

  .secTitle {
    width: 100%;
    text-align: center;

    h2 {
      ${H1DarkGrey};
    }
  }

  .secContent {
    width: 100%;
    text-align: center;

    p {
      ${B1DarkGrey};
    }
  }
`

const LogoContent = ({ logoContent }) => {
  return (
    <LogoContentSection>
      <div className="wrapper">
        <div className="secLogo">
          <div className="secLogo__wrapper">
            <Img
              fluid={
                logoContent.acf._kon_locose_logo.localFile.childImageSharp.fluid
              }
              alt={logoContent.acf._kon_locose_logo.alt_text}
            />
          </div>
        </div>
        <div className="secTitle">
          <h2>{logoContent.acf._kon_locose_title}</h2>
        </div>
        <div
          className="secContent"
          dangerouslySetInnerHTML={{
            __html: logoContent.acf._kon_locose_content,
          }}
        />
      </div>
    </LogoContentSection>
  )
}

export default LogoContent
