import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import {
  medWrapper,
  H1DarkGrey,
  B2DarkGrey,
  B1DarkGrey,
  fonts,
  colors,
  Btn1LimeGreen,
} from "../../../styles/helpers"
import { createSlug } from "../../../utils/helperFunc"

const CallActionSection = styled.section`
  position: relative;
  padding-bottom: 5rem;

  @media (min-width: 768px) {
    padding-bottom: 10rem;
  }

  @media (min-width: 1025px) {
    padding-bottom: 10vw;
  }

  .wrapper {
    ${medWrapper};
    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(55% - 4rem);
      margin-left: 4rem;
    }

    @media (min-width: 1025px) {
      width: calc(65% - 20rem);
      margin-right: 10rem;
      margin-left: 10rem;
    }

    h2 {
      ${H1DarkGrey};
    }

    h3 {
      ${B2DarkGrey};
    }

    ul {
      padding: 2.5rem 0;
    }

    li {
      ${B1DarkGrey};
      position: relative;
      margin-bottom: 0.25em;
      padding-left: 1.25em;
      font-size: 1.6rem;

      &::before {
        font-family: ${fonts.fontAwesome};
        position: absolute;
        top: 0.25rem;
        left: 0;
        padding-right: 0.5em;
        color: ${colors.colorPrimary};
        font-size: 1rem;
        content: "\f111";
      }
    }

    a {
      ${Btn1LimeGreen};
    }

    &__button {
      text-align: center;
    }
  }

  .imageSpacer {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(45%);
    }

    @media (min-width: 1025px) {
      width: calc(35%);
    }
  }

  .image {
    width: 100%;
    max-height: 30rem;

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 45vw;
      height: 100%;
      max-width: 55rem;
      max-height: auto;
    }

    @media (min-width: 1025px) {
      width: 35vw;
    }
  }
`

const CallAction = ({ callAction }) => {
  const slug = createSlug(callAction.acf._kon_call_action_button_link)
  return (
    <CallActionSection>
      <div className="wrapper">
        <div className="content">
          <div>
            <h2>{callAction.acf._kon_call_action_title}</h2>
            <h3>{callAction.acf._kon_call_action_sub_title}</h3>
          </div>
          <div>
            <ul>
              {callAction.acf._kon_call_action_points.map((point, index) => {
                return <li key={index}>{point.point}</li>
              })}
            </ul>
          </div>
          <div className="content__button">
            <Link to={`/${slug}`}>
              {callAction.acf._kon_call_action_button_text}
            </Link>
          </div>
        </div>
        <div className="imageSpacer" />
      </div>
      <div className="image">
        <Img
          fluid={
            callAction.acf._kon_call_action_image.localFile.childImageSharp
              .fluid
          }
          alt={callAction.acf._kon_call_action_title}
        />
      </div>
    </CallActionSection>
  )
}

export default CallAction
