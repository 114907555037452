import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { colors, H2DarkGrey, B1DarkGrey } from "../../../styles/helpers"

import OneScreens from "../../Images/OneScreen"

const ModalItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
  transform: ${props =>
    props.isActive ? "translateX(0%)" : "translateX(-150%)"};
  background: rgba(0, 0, 0, 0.35);
  z-index: 999999999;

  .modalInside {
    position: absolute;
    top: 10%;
    right: 15%;
    bottom: 10%;
    left: 0;
    background: ${colors.white};

    &::before {
      position: absolute;
      top: 25%;
      left: 0;
      width: 30%;
      height: 8.7rem;
      background-color: ${colors.colorSecondary};
      content: "";
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 90%;
      height: 2.2rem;
      background-color: ${colors.colorPrimary};
      content: "";
    }
  }

  .itemWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 4rem;
  }

  .itemImage {
    width: calc(100% - 5rem);
    margin: auto 2.5rem;

    @media (min-width: 768px) {
      width: calc(66.66% - 4rem);
      margin: 0;
      margin-right: 4rem;
    }

    @media (min-width: 1025px) {
      width: calc(70% - 8rem);
      margin-left: 4rem;
    }

    .itemImageContainer {
      position: relative;
      width: 100%;
      max-width: 75.4rem;
      margin: auto;

      .gatsby-image-wrapper {
        max-width: 50rem;
      }
    }

    .innerScreen {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 75%;
    }
  }

  .itemContent {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(33.33%);
    }

    @media (min-width: 1025px) {
      width: calc(30%);
    }

    h2 {
      ${H2DarkGrey};
    }

    p {
      ${B1DarkGrey};
    }
  }

  .buttonWrapper {
    position: absolute;
    right: 0;

    button {
      width: 3.5rem;
      height: 3.5rem;
      transition: all 0.3s ease;
      background-color: ${colors.colorPrimary};
      cursor: pointer;
      border: none;

      &:hover {
        background-color: ${colors.colorSecondary};
        color: ${colors.white};
      }
    }
  }
`

const ThreeColumnsModal = ({ whichActive, setWhichActive, data }) => {
  const isActive = whichActive === data.index
  return (
    <ModalItem isActive={isActive}>
      <div className="modalInside">
        <div className="buttonWrapper">
          <button onClick={() => setWhichActive(null)}>&#10005;</button>
        </div>
        <div className="itemWrap">
          <div className="itemImage">
            <div className="itemImageContainer">
              <OneScreens />
              <div className="innerScreen">
                <Img
                  fluid={data.image.localFile.childImageSharp.fluid}
                  alt={data.title}
                />
              </div>
            </div>
          </div>
          <div className="itemContent">
            <div>
              <h2>{data.title}</h2>
            </div>
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
          </div>
        </div>
      </div>
    </ModalItem>
  )
}

export default ThreeColumnsModal
