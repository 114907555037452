import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {
  standardWrapper,
  H2DarkGrey,
  B2DarkGrey,
} from "../../../styles/helpers"

import TracedChameleonRev from "../../Chameleons/TracedChameleonRev"

import TracedChameleon from "../../Chameleons/TracedChameleon"
import SvgTracedChameleon from "../../Chameleons/SvgTracedChameleon"
import SvgTracedChameleonRev from "../../Chameleons/SvgTracedChameleonRev"

gsap.registerPlugin(ScrollTrigger)

const SpecializesSection = styled.section`
  position: relative;

  .wrapper {
    ${standardWrapper};
  }

  .chameleonGrapicRight {
    position: absolute;
    top: -22.5rem;
    right: 5vw;
    width: calc(34.49rem / 1.25);
    height: calc(25.55rem / 1.25);
    z-index: -1;

    @media (min-width: 768px) {
      width: 34.49rem;
      height: 25.55rem;
    }

    svg {
      width: 100%;
    }
  }

  .chameleonGrapicLeft {
    position: absolute;
    bottom: 5rem;
    left: -5vw;
    width: calc(50.49rem / 1.75);
    height: calc(38.19rem / 1.75);

    @media (min-width: 768px) {
      bottom: -7.5rem;
      width: calc(50.49rem / 1.25);
      height: calc(38.19rem / 1.25);
    }

    @media (min-width: 1025px) {
      bottom: -15rem;
      width: calc(50.49rem);
      height: calc(38.19rem);
    }

    svg {
      width: 100%;
    }
  }

  .mainTitle {
    width: 100%;
    text-align: center;

    h2 {
      ${H2DarkGrey};
    }
  }

  .columnsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-top: 3rem;

    .columnDisplay {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50%);
      }

      li {
        ${B2DarkGrey};
        margin-bottom: 0.25rem;

        span {
          display: inline-block;
          padding-right: 4rem;
        }
      }
    }
  }
`

const Specializes = ({ specialPoints }) => {
  let columnCounter = 0
  const chameleon = useRef(null)
  const chameleonRev = useRef(null)

  useEffect(() => {
    const triggerElement = document.querySelector("#specialSection")
    const chameleonSvg = chameleon.current.querySelector(".chamePath")
    const chameleonSvgRev = chameleonRev.current.querySelector(".chamePathRev")

    gsap.set(chameleonSvg, {
      strokeDasharray: chameleonSvg.getTotalLength(),
      strokeDashoffset: chameleonSvg.getTotalLength(),
    })
    gsap.set(chameleonSvgRev, {
      strokeDasharray: chameleonSvgRev.getTotalLength(),
      strokeDashoffset: chameleonSvgRev.getTotalLength(),
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 45%",
          toggleActions: "play none none none",
        },
      })
      .to(chameleonSvg, { strokeDashoffset: 0, duration: 4 })
      .to(chameleonSvgRev, { strokeDashoffset: 0, duration: 3 }, "-=1")
  }, [])
  return (
    <SpecializesSection id="specialSection">
      <div className="wrapper">
        <div className="mainTitle">
          <h2>{specialPoints.acf._kon_specializes_main_title}</h2>
        </div>
        <div className="columnsWrapper">
          <div className="columnDisplay columnDisplay-one">
            <ul>
              {specialPoints.acf._kon_specializes_points_one.map(
                (item, index) => {
                  columnCounter = columnCounter + 1
                  return (
                    <li key={index}>
                      <span>{columnCounter}. </span>
                      {item.item}
                    </li>
                  )
                }
              )}
            </ul>
          </div>
          <div className="columnDisplay columnDisplay-two">
            <ul>
              {specialPoints.acf._kon_specializes_points_two.map(
                (item, index) => {
                  columnCounter = columnCounter + 1
                  return (
                    <li key={index}>
                      <span>{columnCounter}. </span>
                      {item.item}
                    </li>
                  )
                }
              )}
            </ul>
          </div>
        </div>
      </div>
      <div ref={chameleon} className="chameleonGrapic chameleonGrapicRight">
        <SvgTracedChameleon />
      </div>
      <div ref={chameleonRev} className="chameleonGrapic chameleonGrapicLeft">
        <SvgTracedChameleonRev />
      </div>
    </SpecializesSection>
  )
}

export default Specializes
