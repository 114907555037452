import React, { useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { medWrapper, H1DarkGrey, B1DarkGrey } from "../../../styles/helpers"
gsap.registerPlugin(ScrollTrigger)

const IntroSection = styled.section`
  padding-top: 5rem;

  .wrapper {
    ${medWrapper};
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 6rem);
      margin-right: 6rem;
    }

    &__title {
      max-width: 35rem;
      opacity: 0;

      h2 {
        ${H1DarkGrey};
      }
    }

    &__paragraphs {
      opacity: 0;
    }

    p {
      ${B1DarkGrey};
    }
  }

  .image {
    width: 100%;
    opacity: 0;

    @media (min-width: 768px) {
      width: calc(50%);
    }
  }
`

const Intro = ({ intro }) => {
  useEffect(() => {
    const triggerElement = document.querySelector("#solutionInto")
    const introTitle = triggerElement.querySelector(".content__title")
    const introPara = triggerElement.querySelector(".content__paragraphs")
    const introImage = [...triggerElement.querySelectorAll(".image")]

    gsap.set([introTitle, introPara], {
      autoAlpha: 0,
      y: 100,
    })

    gsap.set([introImage], {
      autoAlpha: 0,
      x: 100,
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 45%",
          toggleActions: "play none none none",
        },
      })
      .to(introTitle, { autoAlpha: 1, y: 0, duration: 0.5 })
      .to(introPara, { autoAlpha: 1, y: 0, duration: 0.5 }, "-=0.25")
      .to(
        introImage,
        {
          autoAlpha: 1,
          x: 0,
          duration: 1,
        },
        "-=0.25"
      )
  }, [])

  return (
    <IntroSection id="solutionInto">
      <div className="wrapper">
        <div className="content">
          <div className="content__title">
            <h2>{intro.acf._kon_soluin_title}</h2>
          </div>
          <div
            className="content__paragraphs"
            dangerouslySetInnerHTML={{ __html: intro.acf._kon_soluin_content }}
          />
        </div>
        <div className="image">
          <Img
            fluid={intro.acf._kon_soluin_image.localFile.childImageSharp.fluid}
            alt={intro.acf._kon_soluin_image.alt_text}
          />
        </div>
      </div>
    </IntroSection>
  )
}

export default Intro
