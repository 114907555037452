import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Intro from "../components/TemplateComponets/Solutions/Intro"
import ThreeColumns from "../components/TemplateComponets/Solutions/ThreeColumns"
import Specializes from "../components/TemplateComponets/Solutions/Specializes"
import LogoContent from "../components/TemplateComponets/Solutions/LogoContent"
import WysiwygSec from "../components/TemplateComponets/Solutions/WysiwygSec"
import CallAction from "../components/TemplateComponets/Solutions/CallAction"

const solutions = props => {
  const {
    seoInfo,
    intro,
    threeColumns,
    specialPoints,
    logoContent,
    wysiwygSec,
    callAction,
  } = props.data
  const location = props.location

  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location.pathname}
      />
      <Intro intro={intro} />
      <ThreeColumns threeColumns={threeColumns} />
      <Specializes specialPoints={specialPoints} />
      <LogoContent logoContent={logoContent} />
      <WysiwygSec wysiwygSec={wysiwygSec} />
      <CallAction callAction={callAction} />
    </Layout>
  )
}

export const solutionsQuery = graphql`
  query solutionsPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    intro: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_soluin_title
        _kon_soluin_content
        _kon_soluin_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    threeColumns: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_tcccon_content_items {
          title
          content
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    specialPoints: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_specializes_main_title
        _kon_specializes_points_one {
          item
        }
        _kon_specializes_points_two {
          item
        }
      }
    }

    logoContent: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_locose_title
        _kon_locose_content
        _kon_locose_logo {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    wysiwygSec: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_solutions_wysiwyg_section
      }
    }

    callAction: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_call_action_title
        _kon_call_action_sub_title
        _kon_call_action_points {
          point
        }
        _kon_call_action_button_text
        _kon_call_action_button_link
        _kon_call_action_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default solutions
