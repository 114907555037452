import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { medWrapper, colors } from "../../../styles/helpers"

import ThreeScreens from "../../Images/ThreeScreens"
import ThreeColumnsSection from "./ThreeColumnsSection"
import ThreeColumnsModal from "./ThreeColumnsModal"

const ThreeColumnsSec = styled.section`
  padding-top: 15rem;
  padding-bottom: 5rem;

  @media (min-width: 768px) {
    padding-top: 20rem;
  }

  .wrapper {
    ${medWrapper};
    position: relative;
    max-width: 100% !important;
  }

  .columnBack {
    position: absolute;
    top: -10rem;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 0% 100%, 100% 80%, 100% 20%);
    background-image: linear-gradient(
      to bottom,
      ${colors.colorSecondary},
      #071d32
    );
    z-index: -1;
  }

  .mainImage {
    position: relative;
    width: 100%;
    z-index: 10;
  }

  .screenItem {
    position: absolute;
    z-index: 1;
    background-color: ${colors.white};

    &.screenItem-1,
    &.screenItem-3 {
      top: 41%;
      width: 22vw;
      height: 15.7vw;

      @media (min-width: 768px) {
        width: 23vw;
      }

      @media (min-width: 1025px) {
        width: 23vw;
      }
    }

    &.screenItem-1 {
      left: 5vw;
    }

    &.screenItem-2 {
      top: 1.5rem;
      left: 50%;
      width: 33vw;
      height: 20vw;
      transform: translateX(-51%);

      @media (min-width: 768px) {
        width: 32.5vw;
      }
    }

    &.screenItem-3 {
      right: 5.5vw;
    }

    .gatsby-image-wrapper {
      width: 100% !important;
    }
  }

  .wrapperBot {
    ${medWrapper};
  }
`

const ThreeColumns = ({ threeColumns }) => {
  const [whichActive, setWhichActive] = useState(null)

  return (
    <ThreeColumnsSec>
      <div className="wrapper">
        <div className="mainImage">
          <ThreeScreens />
          {threeColumns.acf._kon_tcccon_content_items.map((item, index) => {
            return (
              <div className={`screenItem screenItem-${index + 1}`} key={index}>
                <Img
                  fluid={item.image.localFile.childImageSharp.fluid}
                  alt={item.title}
                />
              </div>
            )
          })}
        </div>
        <div className="columnBack" />
      </div>

      <div className="wrapperBot">
        {threeColumns.acf._kon_tcccon_content_items.map((item, index) => (
          <ThreeColumnsSection
            key={index}
            whichActive={whichActive}
            setWhichActive={setWhichActive}
            data={{ ...item, index }}
          />
        ))}
      </div>

      {/* {threeColumns.acf._kon_tcccon_content_items.map((item, index) => (
        <ThreeColumnsModal
          key={index}
          whichActive={whichActive}
          setWhichActive={setWhichActive}
          data={{ ...item, index }}
        />
      ))} */}
    </ThreeColumnsSec>
  )
}

export default ThreeColumns
