import React from "react"
import styled from "styled-components"
import { standardWrapper } from "../../../styles/helpers"

import Wysiwyg from "./Wysiwyg"

const WysiwygSecSection = styled.section`
  .wrapper {
    ${standardWrapper};

    @media (min-width: 1025px) {
      max-width: 75rem;
    }
  }
`

const WysiwygSec = ({ wysiwygSec }) => {
  return (
    <WysiwygSecSection>
      <div className="wrapper">
        <Wysiwyg content={wysiwygSec.acf._kon_solutions_wysiwyg_section} />
      </div>
    </WysiwygSecSection>
  )
}

export default WysiwygSec
